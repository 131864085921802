<template>
  <div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <amplify-authenticator :authConfig="authConfig" usernameAlias="email" class="min-h-screen -mt-16 flex flex-col justify-center sm:w-full md:w-1/2 mx-auto" />
    </div>
  </div>
</template>
<script>

import { AmplifyEventBus } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'

export default {
  name: 'Login',
  async beforeCreate () {
    try {
      await Auth.currentAuthenticatedUser()
      this.$router.push({ path: '/' })
    } catch (err) {}
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.$router.push({ path: '/' })
      }
    })
  },
  data () {
    return {
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false
        }
      }
    }
  }
}
</script>
